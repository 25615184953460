<template>
    <div class="mt-3">
        <v-row :align="'stretch'">
            <v-col cols="12" lg="8">
                <div v-if="isLoading">
                  <v-skeleton-loader type="card-avatar, article"></v-skeleton-loader>
                </div>
                <v-sheet v-else :rounded="'lg'">
                    <v-card-title class="align-start d-flex">
                        <v-avatar class="elevation-3" color="primary" size="38">
                        <v-icon color="white"> mdi-update </v-icon>
                        </v-avatar>
                        <span class="font-weight-bold text-center ml-3">
                        Refills Stats</span
                        >
                    </v-card-title>

                    <v-card-text class="align-start mt-2">
                
                        <DoughnutChart :labels="refillsType" :data="refillsTypeData" cutout="20" ref="stats"/>
                        
                    </v-card-text>
                </v-sheet>
            </v-col>


            <v-col v-if="isLoading" cols="12" lg="4">
                <v-card flat class="rounded-lg mt-3" v-for="item in 4" :key="item">
                  <v-card-text>
                    <v-skeleton-loader type="article" height="70"></v-skeleton-loader>
                  </v-card-text>
                </v-card>
            </v-col>
            <v-col v-else cols="12" lg="4">
                <v-sheet :rounded="'lg'">
                    <v-card-title class="align-start d-flex">
                        <v-avatar class="elevation-3" color="secondary" size="24">
                            
                        </v-avatar>
                        <span class="font-weight-bold text-center ml-3">
                            Total Refills:
                        </span>
                        
                    </v-card-title>

                    <v-card-text class="align-start mt-2">
                
                        <p class="font-weight-black text-lg mb-1 title">
                            <!-- {{ 25900}} -->
                            {{
                               CurrencyFormatting(totals.total_amount)
                            }}.00 DZD
                        </p>
                        
                    </v-card-text>
                </v-sheet>

                <v-sheet :rounded="'lg'" class="mt-3">
                    <v-card-title class="align-start d-flex">
                        <v-avatar class="elevation-3" color="primary" size="24">                            
                        </v-avatar>
                        <span class="font-weight-bold text-center ml-3">
                            Accepted :
                        </span>                        
                    </v-card-title>

                    <v-card-text class="align-start">                
                        <p class="font-weight-black text-lg mb-1 title">
                            <!-- {{ 25900}} -->
                            {{
                               CurrencyFormatting(totals.total_amount_status_accepted)
                            }}.00 DZD
                        </p>
                    </v-card-text>
                </v-sheet>

                <v-sheet :rounded="'lg'" class="mt-3">
                    <v-card-title class="align-start d-flex">
                        <v-avatar class="elevation-3" color="pink" size="24">                            
                        </v-avatar>
                        <span class="font-weight-bold text-center ml-3">
                            Pending :
                        </span>                        
                    </v-card-title>

                    <v-card-text class="align-start">                
                        <p class="font-weight-black text-lg mb-1 title">
                            <!-- {{ 25900}} -->
                            {{
                               CurrencyFormatting(totals.total_amount_status_pending)
                            }}.00 DZD
                        </p>
                    </v-card-text>
                </v-sheet>

                <v-sheet :rounded="'lg'" class="mt-3">
                    <v-card-title class="align-start d-flex">
                        <v-avatar class="elevation-3" color="blue" size="24">                            
                        </v-avatar>
                        <span class="font-weight-bold text-center ml-3">
                            Verification :
                        </span>                        
                    </v-card-title>

                    <v-card-text class="align-start">                
                        <p class="font-weight-black text-lg mb-1 title">
                            <!-- {{ 25900}} -->
                            {{
                               CurrencyFormatting(totals.total_amount_status_verification)
                            }}.00 DZD
                        </p>
                    </v-card-text>
                </v-sheet>

                <v-sheet :rounded="'lg'" class="mt-3">
                    <v-card-title class="align-start d-flex">
                        <v-avatar class="elevation-3" color="red" size="24">                            
                        </v-avatar>
                        <span class="font-weight-bold text-center ml-3">
                            Rejected :
                        </span>                        
                    </v-card-title>

                    <v-card-text class="align-start">                
                        <p class="font-weight-black text-lg mb-1 title">
                            <!-- {{ 25900}} -->
                            {{
                               CurrencyFormatting(totals.total_amount_status_refused)
                            }}.00 DZD
                        </p>
                    </v-card-text>
                </v-sheet>
            </v-col>
        </v-row>

        <v-row :align="'stretch'" class="mt-5">
          <v-col cols="12" lg="12">
            <div v-if="isLoading">
                <v-skeleton-loader type="table"></v-skeleton-loader>
            </div>

            <v-sheet v-else :rounded="'lg'">
              <v-card-title class="align-start d-flex">
                <v-avatar class="elevation-3" color="primary" size="38">
                  <v-icon color="white"> mdi-update </v-icon>
                </v-avatar>
                <span class="font-weight-bold text-center ml-3">
                  Refills
                </span>
              </v-card-title>

              <v-card-text class="align-start mt-2">
                
                <div v-if="isLoading">
                    <v-skeleton-loader type="table"></v-skeleton-loader>
                </div>

                <div v-else>
                   
                <v-data-table
                  :headers="headersStats"
                  :items="stats"
                  :items-per-page="10"
                  class="elevation-0"
                >
                    <template slot="body.append">
                      <tr>
                        <td colspan="2" class="text-center">TOTAL</td>
                        <td class="text-right">{{ CurrencyFormatting(totals.total_amount)}}</td>
                        <td class="text-right">{{ CurrencyFormatting(totals.total_amount_in)}}</td>
                        <td class="text-right">{{ CurrencyFormatting(totals.total_amount_out)}}</td>
                        <td class="text-right">{{ CurrencyFormatting(totals.total_amount_status_accepted)}}</td>
                        <td class="text-right">{{ CurrencyFormatting(totals.total_amount_status_pending)}}</td>
                        <td class="text-right">{{ CurrencyFormatting(totals.total_amount_status_verification)}}</td>
                        <td class="text-right">{{ CurrencyFormatting(totals.total_amount_status_refused)}}</td>
                        </tr>
                        </template>
                </v-data-table>
                </div>
                
              </v-card-text>
            </v-sheet>
          </v-col>
        </v-row>
    </div>
</template>

<script>
import { HTTP } from "@/http-common";
import DoughnutChart from "../../components/charts/DoughnutChart.vue";

export default {
    components: {DoughnutChart},
    props: [],
    data() {
        return {
            isLoading: false,
            
            totals: {
            },
            refillsType: ["Acceped", "Pending", "Verification", "Rejected"],
            refillsTypeData: [],
            stats: [],
            account_ids : [],

            headersStats: [
                {
                    text: 'ID',
                    align: 'start',
                    sortable: false,
                    value: 'account_id',
                },
                { text: 'Nom', value: 'account_name' },
                { text: 'Refills', value: 'total_amount', align: 'end' },
                { text: 'Refills In', value: 'total_amount_in' , align: 'end'},
                { text: 'Refills Out', value: 'total_amount_out' , align: 'end'},
                { text: 'Accepted', value: 'total_amount_status_accepted' , align: 'end'},
                { text: 'Pending', value: 'total_amount_status_accepted' , align: 'end'},
                { text: 'Verification', value: 'total_amount_status_verification' , align: 'end'},
                { text: 'Refused', value: 'total_amount_status_refused' , align: 'end'}
            ]
        }
        
    },
    methods: {
        fetchData(account_ids, formFilter) {
            this.account_ids = account_ids;

            //if( !( this.account_ids.length > 0) ) return;

            this.isLoading = true
            HTTP.post("/transaction/stats_refills", {
                account_ids: this.account_ids,
                startDate: formFilter.startDate,
                endtDate: formFilter.endtDate
            }
            ,{ params: {} })
                .then((res) => {
                    this.stats = res.data;
                    
                    this.totals ={
                        total_amount: 0,
                        total_amount_in: 0,
                        total_amount_out: 0,
                        total_amount_status_accepted: 0,
                        total_amount_status_refused: 0,
                        total_amount_status_pending: 0,
                        total_amount_status_verification: 0
                    }
                    this.stats.forEach(item => {
                        this.totals.total_amount += item.total_amount
                        this.totals.total_amount_in += item.total_amount_in
                        this.totals.total_amount_out += item.total_amount_out
                        this.totals.total_amount_status_accepted += item.total_amount_status_accepted
                        this.totals.total_amount_status_refused += item.total_amount_status_refused
                        this.totals.total_amount_status_verification += item.total_amount_status_verification
                        this.totals.total_amount_status_pending += item.total_amount_status_pending
                    });

                    this.refillsTypeData = [
                        this.getPercent(this.totals.total_amount_status_accepted, this.totals.total_amount),
                        this.getPercent(this.totals.total_amount_status_pending, this.totals.total_amount),
                        this.getPercent(this.totals.total_amount_status_verification, this.totals.total_amount),
                        this.getPercent(this.totals.total_amount_status_refused, this.totals.total_amount)
                    ]
                    this.isLoading = false
                    this.$emit('endLoading')

                })
                .catch((err) => {
                    console.log(err)
                });
      },
    },
    mounted(){
       
    },
    watch: {
    },
}
</script>