<template>
    
    <div class=" my-5 d-flex">
        
           <v-img 
          contain
           :src="(image != null && image.startsWith('http')) ? image : require('@/assets/' + image + '')"
           max-height="30" max-width="40"></v-img>
 
           <div class="d-flex align-center flex-grow-1 flex-wrap">
             <div class="me-auto pl-3">
               <h4 class="subtitle-2">{{ operator}}</h4>

               
             </div>
             <div>
               <p class="text--primary subtitle-1 mb-1 px-5">{{percent }} % </p>
               <v-progress-linear
                 v-model="percent"
                 color="success accent-4"
               ></v-progress-linear>

               <h4 class="subtitle-1 text-center">{{ CurrencyFormatting(amount) }}.00 DA</h4>
             </div>
           </div>
           
         </div>
 </template>
 
 <script>
 export default {
   props: ["amount", "percent", "operator", "image"],
 };
 </script>
 