<template>
    <div>
        <TransactionItem v-for="(supplier, index) in suppliers" :key="index" :amount="supplier.total"
            :percent="getPercent(supplier.total, total_consumed)" :operator="supplier.name"
            :image="getImageLink(supplier.image)" />
    </div>
</template>

<script>
import TransactionItem from './components/TransactionItem.vue';

export default {
    props: ['suppliers', 'total_consumed'],
    components: { TransactionItem },

    methods: {
        
    },
   
}
</script>

<style scoped></style>