<template>
  <v-container fluid>
    <div class="main">
      <v-expansion-panels flat class="mb-4">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <div><v-icon>mdi-filter</v-icon> Filtrage</div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-form ref="form" lazy-validation>
              <div class="d-flex justify-center">
                <h3>Analyse par compte</h3>
              </div>
              <v-row class="d-flex justify-center mb-0">
                <v-col cols="12" sm="12" md="6" lg="6" class="mb-0 p-0">
                  <v-select
                    class="pt-5"
                    v-model="selectAccounts"
                    :items="accounts"
                    :item-text="'name'"
                    :item-value="'id'"
                    attach
                    chips
                    label="Comptes"
                    multiple
                    prepend-icon="mdi-account"
                  ></v-select>
                  
                </v-col>
              </v-row>
              <v-row class="d-flex justify-center mt-0">
                <v-col
                  cols="12"
                  sm="12"
                  md="6"
                  lg="3"
                  style="
                    padding-bottom: 0px !important;
                    padding-top: 0px !important;
                  "
                >
                  <v-dialog
                    ref="dialogStartDate"
                    v-model="modalStartDate"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="formFilter.startDate"
                        label="Date début"
                        hide-details
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        clearable
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="formFilter.startDate"
                      @input="
                        [(formFilter.endDate = null), (modalStartDate = false)]
                      "
                    >
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="6"
                  lg="3"
                  style="
                    padding-bottom: 0px !important;
                    padding-top: 0px !important;
                  "
                >
                  <v-dialog
                    ref="dialogEndDate"
                    v-model="modalEndDate"
                    @click="modalEndDate = false"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="formFilter.endDate"
                        label="Date fin"
                        hide-details
                        :disabled="!formFilter.startDate"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        clearable
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      @input="modalEndDate = false"
                      v-model="formFilter.endDate"
                      :min="formFilter.startDate"
                    >
                    </v-date-picker>
                  </v-dialog>
                </v-col>
              </v-row>

              <div class="d-flex justify-center mt-4">
                <v-btn
                  color="success"
                  class="mr-4 mt-4"
                  large
                  rounded
                  elevation="0"
                  :loading="isLoading"
                  @click="fetchData"
                >
                  Analyser
                </v-btn>
              </div>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-row :align="'stretch'">
        <v-col cols="12" lg="8">
          <!-- Cards -->

          <v-row v-if="isLoading">
            <v-col cols="6" lg="4" v-for="item in 3" :key="item">
              <v-card flat class="rounded-lg">
                <v-card-text>
                  <v-skeleton-loader type="article"></v-skeleton-loader>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="mb-3" v-else>
            <v-col cols="6" lg="4">
              <v-card class="rounded-lg flex flex-column" flat></v-card>
              <v-sheet :rounded="'lg'">
                <v-card-title class="align-start">
                  <v-avatar class="elevation-3" color="primary" size="38">
                    <v-icon color="white"> mdi-currency-usd</v-icon>
                  </v-avatar>
                </v-card-title>

                <v-card-text class="align-start">
                  <p class="font-weight-bold text-lg mb-1">Solde</p>
                  <p class="font-weight-black text-lg mb-1 title">
                    {{ CurrencyFormatting(totals.balance) }}.00 DZD
                  </p>
                </v-card-text>
              </v-sheet>
            </v-col>

            <v-col cols="6" lg="4">
              <v-sheet :rounded="'lg'">
                <v-card-title class="align-start">
                  <v-avatar class="elevation-3" color="error" size="38">
                    <v-icon color="white"> mdi-currency-usd</v-icon>
                  </v-avatar>
                </v-card-title>

                <v-card-text class="align-start">
                  <p class="font-weight-bold text-lg mb-1">Total consomation</p>
                  <p class="font-weight-black text-lg mb-1 title">
                    <!-- CurrencyFormatting(34595900) -->
                    {{ CurrencyFormatting(totals.consumed) }}.00 DZD
                  </p>
                </v-card-text>
              </v-sheet>
            </v-col>

            <v-col cols="6" lg="4">
              <v-sheet :rounded="'lg'">
                <v-card-title class="align-start">
                  <v-avatar class="elevation-3" color="blue" size="38">
                    <v-icon color="white"> mdi-currency-usd</v-icon>
                  </v-avatar>
                </v-card-title>

                <v-card-text class="align-start">
                  <p class="font-weight-bold text-lg mb-1">Total Pending</p>
                  <p class="font-weight-black text-lg mb-1 title">
                    {{ CurrencyFormatting(totals.refill_received_pending) }}.00
                    DZD
                  </p>
                </v-card-text>
              </v-sheet>
            </v-col>
          </v-row>
          <!-- End Cards -->

          <!-- Consommations progress -->
          <v-row class="mb-3">
            <v-col>
              <v-card class="elevation-0">
                <v-skeleton-loader
                  v-if="isLoading"
                  type="article"
                ></v-skeleton-loader>
                <div v-else>
                  <v-card-title> Consommation : </v-card-title>
                  <v-card-text>
                    <p class="text--primary subtitle-1 mb-1 px-5">
                      {{ totals.consumed_percent }} %
                    </p>
                    <v-progress-linear
                      v-model="totals.consumed_percent"
                      color="success accent-4"
                    ></v-progress-linear>
                  </v-card-text>
                </div>
              </v-card>
            </v-col>
          </v-row>
          <!-- End Consommations -->

          <v-skeleton-loader
            v-if="isLoading"
            type="card-avatar, article"
          >
          </v-skeleton-loader>
          <v-sheet v-else :rounded="'lg'">
            <v-card-title class="align-start d-flex">
              <v-avatar class="elevation-3" color="primary" size="38">
                <v-icon color="white"> mdi-update </v-icon>
              </v-avatar>
              <span class="font-weight-bold text-center ml-3">
                Transactions par Type
              </span
              >
            </v-card-title>

            <v-card-text class="align-start mt-2">
              <!-- <LineChart :labels="dashboardData.trasaction_evolutions.dates" :data="dashboardData.trasaction_evolutions.amounts" /> -->
              <DoughnutChart
                :labels="consomationsType"
                :data="consomationsTypesData"
                cutout="20"
                ref="stats"
              />
            </v-card-text>
          </v-sheet>
        </v-col>

        <v-col cols="12" lg="4">
          <div v-if="isLoading">
            <v-skeleton-loader
              v-for="item in 5"
              :key="item"
              type="divider, list-item-three-line, card-heading"
            ></v-skeleton-loader>
          </div>

          <v-sheet v-else :rounded="'lg'">
            <v-card-title class="align-start d-flex">
              <v-avatar class="elevation-3" color="error" size="38">
                <v-icon color="white"> mdi-update </v-icon>
              </v-avatar>
              <span class="font-weight-bold text-center ml-3">
                Tendances convertions</span
              >
            </v-card-title>

            <SectionConvertionTitle
              :amount="totals.flexy"
              title="Flexy"
              :percent="getPercent(totals.flexy, totals.consumed)"
            >
            </SectionConvertionTitle>
            <v-divider></v-divider>

            <v-card-text
              class="align-start mt-0"
              style="overflow-y: auto; height: 100%"
            >
              <TransactionItem
                :amount="totals.flexy_djezzy"
                :percent="getPercent(totals.flexy_djezzy, totals.consumed)"
                operator="Djezzy"
                image="djezzy.svg"
                class="mt-2"
              />

              <v-divider></v-divider>
              <TransactionItem
                :amount="totals.flexy_ooredoo"
                :percent="getPercent(totals.flexy_ooredoo, totals.consumed)"
                operator="OOREDOO"
                image="ooredoo.svg"
              />
              <v-divider></v-divider>
              <TransactionItem
                :amount="totals.flexy_mobilis"
                :percent="getPercent(totals.flexy_mobilis, totals.consumed)"
                operator="Mobilis"
                image="mobilis.svg"
              />
              <v-divider></v-divider>

              <SectionConvertionTitle
                :amount="totals.idoom"
                title="Cards"
                :percent="getPercent(totals.idoom, totals.consumed)"
              >
              </SectionConvertionTitle>
              <v-divider></v-divider>

              <TransactionItem
                :amount="totals.idoom_idoom"
                :percent="getPercent(totals.idoom_idoom, totals.consumed)"
                operator="Idoom"
                image="idoom.svg"
              />
              <v-divider></v-divider>

              <TransactionItem
                :amount="totals.idoom_4g"
                :percent="getPercent(totals.idoom_4g, totals.consumed)"
                operator="Idoom"
                image="4g.jpg"
              />

              <v-divider></v-divider>

              <SectionConvertionTitle
                :amount="totals.voucher"
                title="Vouchers"
                :percent="getPercent(totals.voucher, totals.consumed)"
              >
              </SectionConvertionTitle>
              <v-divider></v-divider>

              <Suppliers :suppliers="suppliers" :total_consumed="totals.voucher" />

              <!-- <TransactionItem
                :amount="totals.voucher_jumia"
                :percent="getPercent(totals.voucher_jumia, totals.consumed)"
                operator="Jumia"
                image="jumia3.svg"
              />

              <v-divider></v-divider>
              <TransactionItem
                :amount="totals.voucher_yassir"
                :percent="getPercent(totals.voucher_yassir, totals.consumed)"
                operator="Yassir"
                image="yassir.png"
              />

              <v-divider></v-divider>
              <TransactionItem
                :amount="totals.voucher_temtem"
                :percent="getPercent(totals.voucher_temtem, totals.consumed)"
                operator="TemTem"
                image="temtem.png"
              /> -->
            </v-card-text>
          </v-sheet>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <div v-if="isLoading">
            <v-skeleton-loader type="table"></v-skeleton-loader>
          </div>
          <div v-else>
            <div>
              <v-data-table
                :headers="headersStats"
                :items="statistiques"
                :items-per-page="10"
                class="elevation-0"
              >
                <template v-slot:item.total_flexy="{ item }">
                  <td :class="lighten + ' text-right'">
                    {{ item.total_flexy }}
                  </td>
                </template>

                <template v-slot:item.total_voucher="{ item }">
                  <td :class="lighten + ' text-right'">
                    {{ item.total_voucher }}
                  </td>
                </template>

                <template v-slot:item.total_idoom="{ item }">
                  <td :class="lighten + ' text-right'">
                    {{ item.total_idoom }}
                  </td>
                </template>

                <template slot="body.append">
                  <tr>
                    <td class="font-weight-bold text-center" colspan="2">
                      TOTALS
                    </td>
                    <td class="text-right">
                      {{ CurrencyFormatting(totals.received) }}
                    </td>
                    <td class="text-right">
                      {{ CurrencyFormatting(totals.balance) }}
                    </td>
                    <td class="text-right">
                      {{ CurrencyFormatting(totals.consumed) }}
                    </td>

                    <td :class="lighten + ' text-right'">
                      {{ CurrencyFormatting(totals.flexy) }}
                    </td>
                    <td class="text-right">
                      {{ CurrencyFormatting(totals.flexy_mobilis) }}
                    </td>
                    <td class="text-right">
                      {{ CurrencyFormatting(totals.flexy_ooredoo) }}
                    </td>
                    <td class="text-right">
                      {{ CurrencyFormatting(totals.flexy_djezzy) }}
                    </td>
                    <td :class="lighten + ' text-right'">
                      {{ CurrencyFormatting(totals.voucher) }}
                    </td>
                    <td class="text-right">
                      {{ CurrencyFormatting(totals.voucher_jumia) }}
                    </td>

                    <td class="text-right">
                      {{ CurrencyFormatting(totals.voucher_yassir) }}
                    </td>

                    <td class="text-right">
                      {{ CurrencyFormatting(totals.voucher_temtem) }}
                    </td>

                    <td :class="lighten + ' text-right'">
                      {{ CurrencyFormatting(totals.idoom) }}
                    </td>

                    <td class="text-right">
                      {{ CurrencyFormatting(totals.idoom_4g) }}
                    </td>

                    <td class="text-right">
                      {{ CurrencyFormatting(totals.idoom_idoom) }}
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </div>

            <div v-if="statistiques.length > 0">
              
            </div>
          </div>
        </v-col>
      </v-row>

      <Refills :id_accounts="selectAccounts" ref="refills" @endLoading="fetchTimeLine()"/>

      <TimeLine ref="timeline"/>


      
    </div>
  </v-container>
</template>

<script>
import DoughnutChart from "../../components/charts/DoughnutChart.vue";
import TransactionItem from "./components/TransactionItem";
import SectionConvertionTitle from "./components/SectionConversionTitle.vue";

import TimeLine from "./TimeLine.vue";

import Refills from "./Refills.vue";

import { HTTP } from "@/http-common";
import Suppliers from "./Suppliers.vue";

export default {
  components: {
    DoughnutChart,
    TransactionItem,
    Refills,
    TimeLine,
    SectionConvertionTitle,
    Suppliers
    
},

  data() {
    return {
      data: [],
      idAccount: 147,
      accounts: [],
      selectAccount: null,
      selectAccounts: [],
      consomationsType: ["Flexy", "Voucher", "Idoom"],
      formFilter: {},
      modalStartDate: false,
      modalEndDate: false,
      headersStats: [
        {
          text: "ID",
          align: "start",
          sortable: false,
          value: "user_id",
        },
        { text: "Nom", value: "username" },
        { text: "Received", value: "total_received", align: "end" },
        { text: "Balance", value: "balance", align: "end" },
        { text: "Consumed", value: "total_consumed", align: "end" },
        { text: "Flexy", value: "total_flexy", align: "end" },
        { text: "Mobilis", value: "total_flexy_mobilis", align: "end" },
        { text: "Djezzy", value: "total_flexy_djezzy", align: "end" },
        { text: "Ooredoo", value: "total_flexy_ooredoo", align: "end" },
        { text: "Vouchers", value: "total_voucher", align: "end" },
        { text: "Jumia", value: "total_voucher_Jumia", align: "end" },
        { text: "Yassir", value: "total_voucher_Yassir", align: "end" },
        { text: "TEM:TEM", value: "total_voucher_Tem:Tem", align: "end" },
        { text: "Idoom", value: "total_idoom", align: "end" },
        { text: "4G", value: "total_idoom_4g", align: "end" },
        { text: "Idoom", value: "total_idoom_idoom", align: "end" },
      ],

      isLoading: true,
      statistiques: [],
      totals: {},
      suppliers: [],


      
    };
  },
  methods: {
    CurrencyFormatting(e) {
      return e;
    },
    getAccounts() {
      HTTP.post("/transaction/analyse_accounts")
        .then((res) => {
          var accounts = [];
          res.data.forEach((user) => {
            accounts.push({
              id: user.account_id,
              name: user.name,
            });
          });
          this.accounts = accounts;
          this.selectAccount = accounts[0].id;
          

          //this.fetchData()
           
           this.$refs.refills.fetchData(this.selectAccounts, this.formFilter);
        })
        .catch((err) => {

          console.log(err);
        });
    },
    fetchData() {
      //this.isLoading = true
      
      this.formFilter.selectAccounts = this.selectAccounts;

      // this.$store.dispatch("fetchAccountsStatistiques", {
      //   filter: this.formFilter,
      // });

      var filter = this.formFilter;
      this.isLoading = true;

      HTTP.post("/transaction/stats/history_with", {
            account_ids: filter.selectAccounts,
            startDate: filter.startDate,
            endDate: filter.endDate
         }, {params: {}})
           .then((res) => {
              this.data = res.data;
              // this.getTotals()

              this.isLoading = false;
              this.statistiques =  res.data.stats;
              this.totals =  res.data.totals;

              this.suppliers = res.data.suppliers;

              this.isLoading = false;
              this.getAccounts();

           })
           .catch((err) => {
            this.isLoading = false;
              console.log(err)
           });
     
      // this.$refs.timeline.fetchData(this.selectAccounts);
      // this.$refs.refills.fetchData(this.selectAccounts, this.formFilter);
    },
    getTotals() {},

    fetchTimeLine(){
      this.$refs.timeline.fetchData(this.selectAccounts);
    }
  },
  mounted() {
    //this.getAccounts();
    this.fetchData();
  },

  computed: {
    consomationsTypesData() {
      return [
        parseInt(this.getPercent(this.totals.flexy, this.totals.consumed)),
        parseInt(this.getPercent(this.totals.voucher, this.totals.consumed)),
        parseInt(this.getPercent(this.totals.idoom, this.totals.consumed)),
      ];
    },
    lighten() {
      return "orange lighten-2";
    },
  },
};
</script>

<style scoped>
td {
  min-width: 120px;
  width: auto;
}
</style>