<template>
    <v-card-text
              class="align-start mt-2"
              style="overflow-y: auto; height: 100%"
            >
              <p class="text--primary text-center subtitle-1 px-5 font-weight-bold">
              {{ title }}: {{ CurrencyFormatting(amount) }} DA</p>
              <v-progress-linear
                 v-model="percent"
                 color="success accent-4"
               ></v-progress-linear>
               <h4 class="subtitle-1 text-center">{{ percent }} %</h4>
    </v-card-text>
</template>

<script>
export default {
    props: ["title", "amount", "percent"],
    data() {
        return {

        }
    },
}
</script>