<template>
    <div>

        <v-col cols="12" lg="12">
            <v-sheet :rounded="'lg'">
              <v-card-title class="align-start d-flex">
                <v-avatar class="elevation-3" color="primary" size="38">
                  <v-icon color="white"> mdi-update </v-icon>
                </v-avatar>
                <span class="font-weight-bold text-center ml-3">
                  Transactions par mois</span
                >
              </v-card-title>

              <v-card-text class="align-start mt-2">
                <v-row align="center">
                    <v-col
                        class="d-flex"
                        cols="12"
                        sm="6"
                    >
                        <v-select
                            :items="years"
                            label="Année"
                            v-model="selectedYear"
                            v-on:change="changeYear"
                        ></v-select>
                    </v-col>
                </v-row>

                <div v-if="isLoading">
                    <v-skeleton-loader type="image,article"></v-skeleton-loader>
                    <v-skeleton-loader
                        class="mt-2"
                        type="paragraph"
                    ></v-skeleton-loader>
                </div>
                <MultiLineChart v-else :labels="categories" :data="dataGraph"/>
              </v-card-text>
            </v-sheet>
          </v-col>
    </div>
</template>

<script>

import { HTTP } from "@/http-common";

import MultiLineChart from "../../components/charts/MultiLineChart.vue";


export default{
    components: {MultiLineChart},
    data(){
        return {
            categories: ["Jan", "Fev", "Mar", "Avr", "Mai", "Juin", "Juil", "Aout", "Sept", "Oct", "Nov", "Dec"],
            dataGraph: [],
            selectedYear: new Date().getFullYear(),
            years: [],
            account_ids: [],
            isLoading: true
        }
    },
    mounted(){
        this.years = []
        var currentYear = new Date().getFullYear();
        for(var y = 2020; y <=currentYear; y++){
            this.years.push(y);
        }
    },
    methods: {

        changeYear(){
            this.fetchData(this.account_ids)
        },
        
        updateChart() {
        },
        
        fetchData(account_ids) {
            this.account_ids = account_ids;

            this.isLoading = true
            HTTP.post("/transaction/analyse_account_date", {
                account_ids: this.account_ids,
                year: this.selectedYear
            }
            ,{ params: {} })
                .then((res) => {

                    var data_flexy = [0,0,0,0,0,0,0,0,0,0,0,0];
                    var data_vouchers = [0,0,0,0,0,0,0,0,0,0,0,0];
                    var data_idoom = [0,0,0,0,0,0,0,0,0,0,0,0];

                    res.data.flexy.forEach(flexy => {
                        var month = parseInt(flexy.month)
                        data_flexy[month - 1] = flexy.total_amount
                    });

                    res.data.vouchers.forEach(voucher => {
                        var month = parseInt(voucher.month)
                        data_vouchers[month - 1] = voucher.total_amount
                    });

                    res.data.idoom.forEach(idoom => {
                        var month = parseInt(idoom.month)
                        data_idoom[month - 1] = idoom.total_amount
                    });

                    
                    //
                    
                    this.dataGraph = [
                        {
                            label: "Flexy",
                            borderColor: "#ffab02",
                            pointBackgroundColor: "#d60c56",
                            data: data_flexy,
                            backgroundColor: "#ffab0211",                
                            
                        },

                        {
                            label: "Voucher",
                            borderColor: "#d60c56",
                            pointBackgroundColor: "#d60c56",
                            data: data_vouchers,
                            backgroundColor: "#d60c5611",                
                        },
                        {
                            label: "Idoom",
                            borderColor: "#2196f3",
                            pointBackgroundColor: "#d60c56",
                            data: data_idoom, 
                            backgroundColor: "#2196f311",                

                        }
                    ];
                    this.isLoading = false
                })
                .catch((err) => {
                    console.log(err)
                });
        }
    }
}
</script>

<style scoped>

</style>